import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Img from "gatsby-image";
import BackgroundImage from 'gatsby-background-image';
import {FaPhone} from 'react-icons/fa';

export const query = graphql`
  query {
    logo: file(relativePath: { regex: "/^logo.png$/" }) {
      childImageSharp {
        fixed(width:350, quality:100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    background: file(relativePath: { regex: "/^steak-background.jpg$/" }) {
      childImageSharp {
        fluid(quality:100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },

  }
`

export default ({data}) => 
<div className="vh-100 black-background">
  <div className="h-100 container-fluid">
    <div className="row h-100">
      <BackgroundImage fluid={data.background.childImageSharp.fluid} className="col-md-6 col-12 px-0 d-flex justify-content-center">
        <Img className="mt-4 mt-sm-5 mb-4 mb-sm-5" fixed={data.logo.childImageSharp.fixed} />
      </BackgroundImage>
      <div className="col d-flex flex-column justify-content-center align-items-center black-background px-0 pt-2 pt-md-0">
        {/* <p className="text-light small sans-serif border-bottom mb-0 d-none d-md-block">RED ROSE RESTAURANT</p>
        <p className="text-light small sans-serif mb-2 mb-md-4 d-none d-md-block">WHITE ROCK</p> */}
        <h3 className="text-light sans-serif font-weight-bold pt-4 p-md-0 text-center">CATERING AVAILABLE</h3>
        <p className="text-light sans-serif text-center">
          From corporate lunches to wedding events,
          <br/>
          we cater to parties of any size.
        </p>
        <span>
          <a className="btn btn-outline-light btn-block rounded-0 sans-serif" rel="noopener noreferrer" 
            href="https://drive.google.com/file/d/1HE7KtcWDhpobmThPAXl7hE-2dlT-oODv/view?usp=sharing" target="_blank" >
              CATERING MENU
          </a>
          <a className="btn btn-outline-light btn-block rounded-0 sans-serif" rel="noopener noreferrer" 
            href="https://drive.google.com/file/d/1U5KxvpXUGoxaiYXxBnsV8JaJynZDNz4M/view?usp=sharing" target="_blank" >
              TAKEOUT & DELIVERY MENU
          </a>
          <a className="btn btn-outline-light btn-block rounded-0 sans-serif" rel="noopener noreferrer" 
            href="https://drive.google.com/file/d/1Lmh77salGJJmhygdA_8rMbXY87UFdgol/view?usp=sharing" target="_blank" >
              LUNCH MENU
          </a>
          <a className="btn btn-outline-light btn-block rounded-0 sans-serif" rel="noopener noreferrer" 
            href="https://drive.google.com/file/d/1fGVIZX_LQvGTy9uw13sm3i6zWnfouCqp/view?usp=sharing" target="_blank" >
              DINNER MENU
          </a>
          <a className="btn btn-outline-light btn-block rounded-0 mb-4 sans-serif" rel="noopener noreferrer" 
            href="https://onlinefoodordering.ca/redrose/" target="_blank" >
              ORDER ONLINE
          </a>
        </span>
        <p className="text-light sans-serif mb-1"><span className="font-weight-bold mr-2 " >S. </span>12:00 pm - 9:00 pm</p>
        <p className="text-light sans-serif mb-1"><span className="font-weight-bold mr-2 " >M. </span>11:00 am - 9:00 pm</p>
        <p className="text-light sans-serif mb-1"><span className="font-weight-bold mr-2 " >T. </span>11:00 am - 9:00 pm</p>
        <p className="text-light sans-serif mb-1"><span className="font-weight-bold mr-2 " >W. </span>11:00 am - 9:00 pm</p>
        <p className="text-light sans-serif mb-1"><span className="font-weight-bold mr-2 " >T. </span>11:00 am - 9:00 pm</p>
        <p className="text-light sans-serif mb-1"><span className="font-weight-bold mr-2 " >F. </span>11:00 am - 9:30 pm</p>
        <p className="text-light sans-serif mb-4"><span className="font-weight-bold mr-2 " >S. </span>11:00 am - 9:30 pm</p>
        <p className="text-light sans-serif">
          <FaPhone className="small fa-fw mr-2" /> <a className="text-decoration-none text-light" href="tel:604-535-5542">604 535 5542</a><br/>
          <br/>
          2381 King George Blvd<br/>
          South Surrey, BC<br/>
          V4A 5A4<br/>
        </p>
      </div>
    </div>
  </div>
  <div className="modal" tabIndex="-1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Modal title</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Modal body text goes here.</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
</div>
